"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeCallwords = changeCallwords;
var client_1 = require("../client");
var setCookie_1 = require("../utils/setCookie");
/**
 * Triggered by a changed callword list
 */
function changeCallwords() {
    client_1.client.callwords = (document.getElementById("client_callwords")).value.split("\n");
    (0, setCookie_1.default)("callwords", client_1.client.callwords.join("\n"));
}
window.changeCallwords = changeCallwords;
