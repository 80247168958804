"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mutelist_click = mutelist_click;
var client_1 = require("../client");
/**
 * Triggered when a character in the mute list is clicked
 * @param {MouseEvent} event
 */
function mutelist_click(_event) {
    var mutelist = (document.getElementById('mute_select'));
    var selected_character = mutelist.options[mutelist.selectedIndex];
    if (client_1.client.chars[selected_character.value].muted === false) {
        client_1.client.chars[selected_character.value].muted = true;
        selected_character.text = "".concat(client_1.client.chars[selected_character.value].name, " (muted)");
        console.info("muted ".concat(client_1.client.chars[selected_character.value].name));
    }
    else {
        client_1.client.chars[selected_character.value].muted = false;
        selected_character.text = client_1.client.chars[selected_character.value].name;
    }
}
window.mutelist_click = mutelist_click;
