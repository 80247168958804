"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReconnectButton = ReconnectButton;
var client_1 = require("../client");
var queryParser_1 = require("../utils/queryParser");
var serverIP = (0, queryParser_1.default)().ip;
/**
 * Triggered when the reconnect button is pushed.
 */
function ReconnectButton() {
    client_1.client.cleanup();
    (0, client_1.setClient)(new client_1.default(serverIP));
    if (client_1.client) {
        document.getElementById("client_error").style.display = "none";
    }
}
window.ReconnectButton = ReconnectButton;
