"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onEnter = onEnter;
var client_1 = require("../client");
var encoding_1 = require("../encoding");
/**
 * Triggered when the Return key is pressed on the in-character chat input box.
 * @param {KeyboardEvent} event
 */
function onEnter(event) {
    if (event.keyCode === 13) {
        var mychar = client_1.client.character;
        var myemo = client_1.client.emote;
        var evi = client_1.client.evidence + 1;
        var flip = Boolean(document.getElementById("button_flip").classList.contains("dark"));
        var flash = Boolean(document.getElementById("button_flash").classList.contains("dark"));
        var screenshake = Boolean(document.getElementById("button_shake").classList.contains("dark"));
        var noninterrupting_preanim = Boolean(document.getElementById("check_nonint").checked);
        var looping_sfx = Boolean(document.getElementById("check_loopsfx").checked);
        var color = Number(document.getElementById("textcolor").value);
        var showname = (0, encoding_1.escapeChat)(document.getElementById("ic_chat_name").value);
        var text = document.getElementById("client_inputbox")
            .value;
        var pairchar = document.getElementById("pair_select")
            .value;
        var pairoffset = Number(document.getElementById("pair_offset").value);
        var pairyoffset = Number(document.getElementById("pair_y_offset").value);
        var myrole = document.getElementById("role_select")
            .value
            ? document.getElementById("role_select").value
            : mychar.side;
        var additive = Boolean(document.getElementById("check_additive").checked);
        var effect = document.getElementById("effect_select")
            .value;
        var sfxname = "0";
        var sfxdelay = 0;
        var emote_mod = myemo.zoom;
        if (document.getElementById("sendsfx").checked) {
            sfxname = myemo.sfx;
            sfxdelay = myemo.sfxdelay;
        }
        // not to overwrite a 5 from the ini or anything else
        if (document.getElementById("sendpreanim").checked) {
            if (emote_mod === 0) {
                emote_mod = 1;
            }
        }
        else if (emote_mod === 1) {
            emote_mod = 0;
        }
        client_1.client.sender.sendIC(myemo.deskmod, myemo.preanim, mychar.name, myemo.emote, text, myrole, sfxname, emote_mod, sfxdelay, client_1.selectedShout, evi, flip, flash, color, showname, pairchar, pairoffset, pairyoffset, noninterrupting_preanim, looping_sfx, screenshake, "-", "-", "-", additive, effect);
    }
    return false;
}
window.onEnter = onEnter;
