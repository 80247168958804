"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initCE = initCE;
var client_1 = require("../client");
/**
 * Declare cross examination.
 */
function initCE() {
    client_1.client.sender.sendRT("testimony2");
}
window.initCE = initCE;
