"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.editEvidence = editEvidence;
var client_1 = require("../client");
var cancelEvidence_1 = require("./cancelEvidence");
/**
 * Edit selected evidence.
 */
function editEvidence() {
    var evidence_select = (document.getElementById("evi_select"));
    var id = client_1.client.selectedEvidence;
    client_1.client.sender.sendEE(id, document.getElementById("evi_name").value, document.getElementById("evi_desc").value, evidence_select.selectedIndex === 0
        ? document.getElementById("evi_filename").value
        : evidence_select.options[evidence_select.selectedIndex].text);
    (0, cancelEvidence_1.cancelEvidence)();
}
window.editEvidence = editEvidence;
