"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cancelEvidence = cancelEvidence;
var client_1 = require("../client");
var updateEvidenceIcon_1 = require("./updateEvidenceIcon");
var aoHost_1 = require("../client/aoHost");
/**
 * Cancel evidence selection.
 */
function cancelEvidence() {
    // Clear evidence data
    if (client_1.client.selectedEvidence >= 0) {
        document.getElementById("evi_".concat(client_1.client.selectedEvidence)).className =
            "evi_icon";
    }
    client_1.client.selectedEvidence = -1;
    // Clear evidence on information window
    document.getElementById("evi_select").selectedIndex = 0;
    (0, updateEvidenceIcon_1.updateEvidenceIcon)(); // Update icon widget
    document.getElementById("evi_filename").value = "";
    document.getElementById("evi_name").value = "";
    document.getElementById("evi_desc").value = "";
    (document.getElementById("evi_preview")).src = "".concat(aoHost_1.AO_HOST, "misc/empty.png"); // Clear icon
    // Update button
    document.getElementById("evi_add").className = "client_button hover_button";
    document.getElementById("evi_edit").className =
        "client_button hover_button inactive";
    document.getElementById("evi_cancel").className =
        "client_button hover_button inactive";
    document.getElementById("evi_del").className =
        "client_button hover_button inactive";
}
window.cancelEvidence = cancelEvidence;
