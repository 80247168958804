"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onOOCEnter = onOOCEnter;
var client_1 = require("../client");
/**
 * Triggered when the Return key is pressed on the out-of-character chat input box.
 * @param {KeyboardEvent} event
 */
function onOOCEnter(event) {
    if (event.keyCode === 13) {
        client_1.client.sender.sendOOC(document.getElementById("client_oocinputbox").value);
        document.getElementById("client_oocinputbox").value =
            "";
    }
}
window.onOOCEnter = onOOCEnter;
