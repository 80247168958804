"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeRoleOOC = changeRoleOOC;
var updateActionCommands_1 = require("./updateActionCommands");
var client_1 = require("../client");
/**
 * Change role via OOC.
 */
function changeRoleOOC() {
    var roleselect = document.getElementById("role_select");
    client_1.client.sender.sendOOC("/pos ".concat(roleselect.value));
    client_1.client.sender.sendServer("SP#".concat(roleselect.value, "#%"));
    (0, updateActionCommands_1.updateActionCommands)(roleselect.value);
}
window.changeRoleOOC = changeRoleOOC;
