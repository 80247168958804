"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hcallback = hcallback;
var client_1 = require("../client");
var setCookie_1 = require("../utils/setCookie");
function hcallback(hcaptcharesponse) {
    (0, setCookie_1.default)('hdid', client_1.client.hdid);
    client_1.client.sender.sendServer("2T#".concat(hcaptcharesponse, "#%"));
    location.reload();
}
window.hcallback = hcallback;
