"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.redHPP = redHPP;
var client_1 = require("../client");
/**
 * Decrement prosecution health point.
 */
function redHPP() {
    client_1.client.sender.sendHP(2, client_1.client.hp[1] - 1);
}
window.redHPP = redHPP;
