"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.imgError = imgError;
/**
 * Triggered when there was an error loading a generic sprite.
 * @param {HTMLImageElement} image the element containing the missing image
 */
function imgError(image) {
    image.onerror = null;
    image.src = ""; // unload so the old sprite doesn't persist
    return true;
}
window.imgError = imgError;
