"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addHPP = addHPP;
var client_1 = require("../client");
/**
 * Increment prosecution health point.
 */
function addHPP() {
    client_1.client.sender.sendHP(2, client_1.client.hp[1] + 1);
}
window.addHPP = addHPP;
