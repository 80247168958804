"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.musiclist_click = musiclist_click;
var client_1 = require("../client");
/**
 * Triggered when an item on the music list is clicked.
 * @param {MouseEvent} event
 */
function musiclist_click(_event) {
    var playtrack = (document.getElementById("client_musiclist")).value;
    client_1.client.sender.sendMusicChange(playtrack);
    // This is here so you can't actually select multiple tracks,
    // even though the select tag has the multiple option to render differently
    var musiclist_elements = (document.getElementById("client_musiclist")).selectedOptions;
    for (var i = 0; i < musiclist_elements.length; i++) {
        musiclist_elements[i].selected = false;
    }
}
window.musiclist_click = musiclist_click;
