"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pickEvidence = pickEvidence;
var client_1 = require("../client");
var cancelEvidence_1 = require("./cancelEvidence");
var updateEvidenceIcon_1 = require("./updateEvidenceIcon");
var getIndexFromSelect_1 = require("./getIndexFromSelect");
/**
 * Highlights and selects an evidence for in-character chat.
 * @param {string} evidence the evidence to be presented
 */
function pickEvidence(evidence) {
    if (client_1.client.selectedEvidence !== evidence) {
        // Update selected evidence
        if (client_1.client.selectedEvidence >= 0) {
            document.getElementById("evi_".concat(client_1.client.selectedEvidence)).className =
                "evi_icon";
        }
        document.getElementById("evi_".concat(evidence)).className = "evi_icon dark";
        client_1.client.selectedEvidence = evidence;
        // Show evidence on information window
        document.getElementById("evi_name").value =
            client_1.client.evidences[evidence].name;
        document.getElementById("evi_desc").value =
            client_1.client.evidences[evidence].desc;
        // Update icon
        var icon_id = (0, getIndexFromSelect_1.getIndexFromSelect)("evi_select", client_1.client.evidences[evidence].filename);
        document.getElementById("evi_select").selectedIndex =
            icon_id;
        if (icon_id === 0) {
            document.getElementById("evi_filename").value =
                client_1.client.evidences[evidence].filename;
        }
        (0, updateEvidenceIcon_1.updateEvidenceIcon)();
        // Update button
        document.getElementById("evi_add").className =
            "client_button hover_button inactive";
        document.getElementById("evi_edit").className =
            "client_button hover_button";
        document.getElementById("evi_cancel").className =
            "client_button hover_button";
        document.getElementById("evi_del").className = "client_button hover_button";
    }
    else {
        (0, cancelEvidence_1.cancelEvidence)();
    }
}
window.pickEvidence = pickEvidence;
