"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chartable_filter = chartable_filter;
var client_1 = require("../client");
/**
 * Triggered when the music search bar is changed
 * @param {MouseEvent} event
 */
function chartable_filter(_event) {
    var searchname = (document.getElementById("client_charactersearch")).value;
    client_1.client.chars.forEach(function (character, charid) {
        var demothing = document.getElementById("demo_".concat(charid));
        if (character.name.toLowerCase().indexOf(searchname.toLowerCase()) === -1) {
            demothing.style.display = "none";
        }
        else {
            demothing.style.display = "inline-block";
        }
    });
}
window.chartable_filter = chartable_filter;
