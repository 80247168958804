"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeCharacter = changeCharacter;
/**
 * Triggered when a character icon is clicked in the character selection menu.
 * @param {MouseEvent} event
 */
function changeCharacter(_event) {
    document.getElementById("client_waiting").style.display = "block";
    document.getElementById("client_charselect").style.display = "block";
    document.getElementById("client_emo").innerHTML = "";
}
window.changeCharacter = changeCharacter;
