"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleShout = toggleShout;
var client_1 = require("../client");
/**
 * Highlights and selects a shout for in-character chat.
 * If the same shout button is selected, then the shout is canceled.
 * @param {number} shout the new shout to be selected
 */
function toggleShout(shout) {
    if (shout === client_1.selectedShout) {
        document.getElementById("button_".concat(shout)).className = "client_button";
        (0, client_1.setSelectedShout)(0);
    }
    else {
        document.getElementById("button_".concat(shout)).className = "client_button dark";
        if (client_1.selectedShout) {
            document.getElementById("button_".concat(client_1.selectedShout)).className =
                "client_button";
        }
        (0, client_1.setSelectedShout)(shout);
    }
}
window.toggleShout = toggleShout;
