"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.guilty = guilty;
var client_1 = require("../client");
/**
 * Declare the defendant not guilty
 */
function guilty() {
    client_1.client.sender.sendRT("judgeruling#1");
}
window.guilty = guilty;
