"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notguilty = notguilty;
var client_1 = require("../client");
/**
 * Declare the defendant not guilty
 */
function notguilty() {
    client_1.client.sender.sendRT("judgeruling#0");
}
window.notguilty = notguilty;
