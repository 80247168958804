"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateIniswap = updateIniswap;
/**
 * Update iniswap drowdown
 */
function updateIniswap() {
    var ini_select = (document.getElementById("client_iniselect"));
    var ini_name = (document.getElementById("client_ininame"));
    if (ini_select.selectedIndex === 0) {
        ini_name.style.display = "initial";
    }
    else {
        ini_name.style.display = "none";
    }
}
window.updateIniswap = updateIniswap;
