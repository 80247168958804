"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.musiclist_filter = musiclist_filter;
var client_1 = require("../client");
/**
 * Triggered when the music search bar is changed
 * @param {MouseEvent} event
 */
function musiclist_filter(_event) {
    var e_1, _a;
    var musiclist_element = (document.getElementById("client_musiclist"));
    var searchname = (document.getElementById("client_musicsearch")).value;
    musiclist_element.innerHTML = "";
    try {
        for (var _b = __values(client_1.client.musics), _c = _b.next(); !_c.done; _c = _b.next()) {
            var trackname = _c.value;
            if (trackname.toLowerCase().indexOf(searchname.toLowerCase()) !== -1) {
                var newentry = document.createElement("OPTION");
                newentry.text = trackname;
                musiclist_element.options.add(newentry);
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
}
window.musiclist_filter = musiclist_filter;
