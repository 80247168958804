"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.callMod = callMod;
var client_1 = require("../client");
/**
 * Call mod.
 */
function callMod() {
    var modcall;
    if (client_1.extrafeatures.includes("modcall_reason")) {
        modcall = prompt("Please enter the reason for the modcall", "");
    }
    if (modcall == null || modcall === "") {
        // cancel
    }
    else {
        client_1.client.sender.sendZZ(modcall);
    }
}
window.callMod = callMod;
