"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.charError = charError;
var transparentPng_1 = require("../constants/transparentPng");
/**
 * Triggered when there was an error loading a character sprite.
 * @param {HTMLImageElement} image the element containing the missing image
 */
function charError(image) {
    console.warn("".concat(image.src, " is missing from webAO"));
    image.src = transparentPng_1.default;
    return true;
}
window.charError = charError;
