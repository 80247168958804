"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateEvidenceIcon = updateEvidenceIcon;
var aoHost_1 = require("../client/aoHost");
/**
 * Update evidence icon.
 */
function updateEvidenceIcon() {
    var evidence_select = (document.getElementById("evi_select"));
    var evidence_filename = (document.getElementById("evi_filename"));
    var evidence_iconbox = (document.getElementById("evi_preview"));
    if (evidence_select.selectedIndex === 0) {
        evidence_filename.style.display = "initial";
        evidence_iconbox.src = "".concat(aoHost_1.AO_HOST, "evidence/").concat(encodeURI(evidence_filename.value.toLowerCase()));
    }
    else {
        evidence_filename.style.display = "none";
        evidence_iconbox.src = "".concat(aoHost_1.AO_HOST, "evidence/").concat(encodeURI(evidence_select.value.toLowerCase()));
    }
}
window.updateEvidenceIcon = updateEvidenceIcon;
