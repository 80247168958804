"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.redHPD = redHPD;
var client_1 = require("../client");
/**
 * Decrement defense health point.
 */
function redHPD() {
    client_1.client.sender.sendHP(1, client_1.client.hp[0] - 1);
}
window.redHPD = redHPD;
