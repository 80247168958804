"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addHPD = addHPD;
var client_1 = require("../client");
/**
 * Increment defense health point.
 */
function addHPD() {
    client_1.client.sender.sendHP(1, client_1.client.hp[0] + 1);
}
window.addHPD = addHPD;
