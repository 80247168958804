"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initWT = initWT;
var client_1 = require("../client");
/**
 * Declare witness testimony.
 */
function initWT() {
    client_1.client.sender.sendRT("testimony1");
}
window.initWT = initWT;
