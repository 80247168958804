"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleMenu = toggleMenu;
var client_1 = require("../client");
/**
 * Highlights and selects a menu.
 * @param {number} menu the menu to be selected
 */
function toggleMenu(menu) {
    if (menu !== client_1.selectedMenu) {
        document.getElementById("menu_".concat(menu)).className = "menu_button active";
        document.getElementById("content_".concat(menu)).className =
            "menu_content active";
        document.getElementById("menu_".concat(client_1.selectedMenu)).className = "menu_button";
        document.getElementById("content_".concat(client_1.selectedMenu)).className =
            "menu_content";
        (0, client_1.setSelectedMenu)(menu);
    }
}
window.toggleMenu = toggleMenu;
