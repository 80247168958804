"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.banPlayer = banPlayer;
exports.kickPlayer = kickPlayer;
var client_1 = require("../client");
/**
 * Tries to ban a player from the playerlist
 * @param {Number} id the players id
 */
function banPlayer(id) {
    var reason;
    var length;
    reason = prompt("Please enter the reason", "Being annoying");
    length = Number(prompt("Please enter the ban length in minutes", "60"));
    client_1.client.sender.sendMA(id, length, reason);
}
window.banPlayer = banPlayer;
/**
 * Tries to kick a player from the playerlist
 * @param {Number} id the players id
 */
function kickPlayer(id) {
    var reason;
    reason = prompt("Please enter the reason", "Being annoying");
    client_1.client.sender.sendMA(id, 0, reason);
}
window.kickPlayer = kickPlayer;
