"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeBackgroundOOC = changeBackgroundOOC;
var queryParser_1 = require("../utils/queryParser");
var client_1 = require("../client");
var mode = (0, queryParser_1.default)().mode;
/**
 * Change background via OOC.
 */
function changeBackgroundOOC() {
    var selectedBG = document.getElementById("bg_select");
    var changeBGCommand = "bg $1";
    var bgFilename = document.getElementById("bg_filename");
    var filename = "";
    if (selectedBG.selectedIndex === 0) {
        filename = bgFilename.value;
    }
    else {
        filename = selectedBG.value;
    }
    if (mode === "join") {
        client_1.client.sender.sendOOC("/".concat(changeBGCommand.replace("$1", filename)));
    }
    else if (mode === "replay") {
        client_1.client.sender.sendSelf("BN#".concat(filename, "#%"));
    }
}
window.changeBackgroundOOC = changeBackgroundOOC;
