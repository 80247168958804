"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.randomCharacterOOC = randomCharacterOOC;
var client_1 = require("../client");
/**
 * Random character via OOC.
 */
function randomCharacterOOC() {
    client_1.client.sender.sendOOC("/randomchar");
}
window.randomCharacterOOC = randomCharacterOOC;
