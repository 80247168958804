"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.modcall_test = modcall_test;
var packetHandler_1 = require("../packets/packetHandler");
/**
 * Triggered by the modcall sfx dropdown
 */
function modcall_test() {
    packetHandler_1.packetHandler.get("ZZ")("test#test".split("#"));
}
window.modcall_test = modcall_test;
