"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteEvidence = deleteEvidence;
var client_1 = require("../client");
var cancelEvidence_1 = require("./cancelEvidence");
/**
 * Delete selected evidence.
 */
function deleteEvidence() {
    var id = client_1.client.selectedEvidence;
    client_1.client.sender.sendDE(id);
    (0, cancelEvidence_1.cancelEvidence)();
}
window.deleteEvidence = deleteEvidence;
