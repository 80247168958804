"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pickChar = pickChar;
var client_1 = require("../client");
/**
 * Requests to play as a character.
 * @param {number} ccharacter the character ID; if this is a large number,
 * then spectator is chosen instead.
 */
function pickChar(ccharacter) {
    if (ccharacter === -1) {
        // Spectator
        document.getElementById("client_waiting").style.display = "none";
        document.getElementById("client_charselect").style.display = "none";
    }
    client_1.client.sender.sendCharacter(ccharacter);
}
window.pickChar = pickChar;
